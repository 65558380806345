import { Component, Vue } from 'vue-property-decorator';
import Layout from '@/layouts/basic/basic.vue';
import ShowPath from '@/components/showPath/showPath.vue';
import Title1 from '@/components/title1/title1.vue';
import {} from '@/components/base';

import ListDocumentos from '@/modules/documentos/components/listDocumentos/listDocumentos.vue';
import documentos from '@/modules/documentos/services/documentos';
  
@Component({
  components: {
    Layout,
    Title1,
    ShowPath,
    ListDocumentos,
  }
})
export default class ById extends Vue {
  categoriaTitle = {}

  mounted() {
    const id = this.$route.params.id;
    this.categoriaTitle = documentos.getCategoriaTitle(id);
    if( this.categoriaTitle == '' ){
      this.$router.push('/todos-documentos');
    }
  }


}
  